@import "assets/css/variables.css";
@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 5000;
}

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  /* letter-spacing: 0.2px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fancybox__container {
  pointer-events: auto;
}

@font-face {
  font-family: "Main";
  src: url(./assets/fonts/main.otf);
}

.lv-booking-module {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  border: none;
}

#booking-module-widjet {
  width: 846px;
  height: 82px;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -70%);
  border: none;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-radius: 24px;
}

#booking-module-calendar {
  max-width: 995px;
  max-height: 612px;
  width: calc(100vw - 32px);
  height: calc((100vw - 32px) * 0.615);
  z-index: 1;
  position: absolute;
  border: none;
  border-radius: 4px;
  -webkit-appearance: none;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  -webkit-box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
}

#booking-module-rooms {
  z-index: 1;
  position: absolute;
  border: none;
  border-radius: 4px;
  -webkit-appearance: none;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  -webkit-box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  transition: top 0.2s ease;
}

@media (max-width: calc(768px + 48px)) {
  #booking-module-calendar {
    width: calc(100vw - ((100vw - 768px) / 2));
    height: calc((100vw - ((100vw - 768px) / 2)) * 0.615);
  }
}

@media (max-width: 1024px) {
  #booking-module-widjet {
    width: 781px;
  }
}

@media (max-width: 768px) {
  #booking-module-widjet {
    width: 252px;
    height: 268px;
  }

  #booking-module-calendar {
    border-radius: 0;
    max-width: none;
    max-height: none;
    width: 100dvw;
    height: 100dvh;
  }
}